import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import Button from '@mui/material/Button';

export const Title = styled(Typography)`
  ${({ theme }) => `
  font-family: Poppins SemiBold;
  text-align: center;
  margin-bottom: 1rem;

  ${theme.breakpoints.up('md')} {
   font-size: 2.1em;
   line-height: 50px;
  }
  ${theme.breakpoints.only('sm')} {
   font-size: 1.6em;
   line-height: 40px;
  }
  ${theme.breakpoints.only('xs')} {
   font-size: 1.2em;
   line-height: inherit;
  }
  `}
` as StyledPropsFix;

export const InfoText = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  padding-bottom: 1em;
  text-align: center;

  ${theme.breakpoints.up('md')} {
    font-size: 1em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.8em;
  }
`}
` as StyledPropsFix;

export const ContentBox = styled(Box)`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    background: ${theme.palette.background.paper};
  `}
`;

export const ContentContainer = styled(Container)<{ sizeofgrid?: string }>`
  ${({ sizeofgrid }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100;
   ${!!sizeofgrid && `max-width: ${sizeofgrid}px!important;`}
  position: relative;
  padding-bottom: 5rem;
  padding-top: 3rem;

`}
`;

export const ActionButton = styled(Button)`
  text-transform: none;
  font-family: Roboto Regular;
  color: ${({ theme }) => theme.palette.text.secondary};
  background-color: rgb(64 130 109 / 100%);
  white-space: nowrap;
  &:hover {
    background-color: #58a88f;
  }
  font-size: 1.125em;
  margin-right: 0;
  margin-top: 1rem;
`;
