import React from 'react';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import * as Styles from './styles';
import { Anchor } from 'utils';
import { useLocalise } from 'hooks/use-localise';

const content = [
  {
    id: 1,
    title: 'increasedValue',
    description: 'increasedValue',
  },
  {
    id: 2,
    title: 'rentalIncome',
    description: 'rentalIncome',
  },
  {
    id: 3,
    title: 'averageReturn',
    description: 'averageReturn',
  },
  {
    id: 4,
    title: 'minimumInvestment',
    description: 'minimumInvestment',
  },
  {
    id: 5,
    title: 'co2EmissionsSaved',
    description: 'co2EmissionsSaved',
  },
];

const KeyStatistics = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  const { localise } = useLocalise('IndexView', 'KeyStatistics');

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Anchor id="key-statistics" topDistance={'-5em'} />
        {content.map((item, index) => (
          <Styles.DataPoint key={index}>
            <Styles.DataTitle color="black">
              {localise(item.title + '.title')}
            </Styles.DataTitle>
            <Styles.DataDescription color="black">
              {localise(item.description + '.description')}
            </Styles.DataDescription>
          </Styles.DataPoint>
        ))}
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default KeyStatistics;
