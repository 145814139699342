import React from 'react';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import * as Styles from './styles';
import { useLocalise } from 'hooks/use-localise';

const SHEDULE_CALL_LINK = 'https://calendly.com/grev55/investor-guidance';

const ScheduleCall = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  const { localise } = useLocalise('IndexView', 'ScheduleCall');

  const handleCallToAction = () => {
    window.open(SHEDULE_CALL_LINK, '_blank');
  };

  const adjustedGridSize = sizeOfGrid - 360;

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={adjustedGridSize?.toString()}>
        <Styles.Title component="h1" variant="h4" color="text.primary">
          {localise('title')}
        </Styles.Title>
        <Styles.InfoText component="p" color="text.primary">
          {localise('description')}
        </Styles.InfoText>
        <Styles.ActionButton onClick={handleCallToAction} variant="contained">
          {localise('CTA')}
        </Styles.ActionButton>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default ScheduleCall;
