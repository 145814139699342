import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';

export const DataTitle = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  text-align: center;

  ${theme.breakpoints.up('md')} {
   font-size: 3rem;
   line-height: 50px;
  }
  ${theme.breakpoints.only('sm')} {
   font-size: 1.6em;
   line-height: 40px;
  }
  ${theme.breakpoints.only('xs')} {
   font-size: 1.2em;
   line-height: inherit;
  }
  `}
` as StyledPropsFix;

export const DataDescription = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  padding-bottom: 1em;
  text-align: center;

  ${theme.breakpoints.up('md')} {
    font-size: 1em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.8em;
  }
`}
` as StyledPropsFix;

export const ContentBox = styled(Box)`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    background: ${theme.palette.background.paper};
  `}
`;

export const ContentContainer = styled(Container)<{ sizeofgrid?: string }>`
  ${({ theme, sizeofgrid }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100;
  ${!!sizeofgrid && `max-width: ${sizeofgrid}px!important;`}
  position: relative;
  padding: 70px 30px;

  ${theme.breakpoints.only('xs')} {
    flex-direction: column;
  }
`}
`;

export const DataPoint = styled(Box)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 1rem;
    max-width: 10rem;

    ${theme.breakpoints.only('xs')} {
    margin-bottom: 1rem;
  }
  `}
`;
