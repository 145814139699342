import React from 'react';
import { ChevronDown } from 'images/SVGs';
import Link from 'layouts/Main/components/Footer/FooterLink';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

const SeeMoreButton = ({ href }: { href: string }) => {
  const [isActive, setIsActive] = React.useState(false);

  const handleMouseHover = () => {
    setIsActive(!isActive);
  };

  return (
    <Container onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover}>
      <Link href={href}>
        <ChevronDown active={isActive} />
      </Link>
    </Container>
  );
};

export default SeeMoreButton;

const Container = styled(Box)`
  animation: jumpInfinite 2s infinite;

  @keyframes jumpInfinite {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;
