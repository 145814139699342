import React from 'react';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import * as Styles from './styles';
import { useLocalise } from 'hooks/use-localise';

const VIDEO_LINK =
  'https://www.youtube.com/embed/KM0dKk-MRGo?si=94dWDBhuC3ZCpSqp';

const VideoSection = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  const { localise } = useLocalise('IndexView', 'VideoSection');

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.Title component="h1" variant="h4" color="text.primary">
          {localise('title')}
        </Styles.Title>
        <iframe
          width="100%"
          src={VIDEO_LINK}
          style={{ border: 'none', aspectRatio: '16/9' }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default VideoSection;
