import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { StyledPropsFix } from 'types';
import heroBackground from 'images/indexPage/hero_image_grev55.webp';
import heroMobileBackground from 'images/indexPage/hero_image_mobile_grev55.webp';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export const HeroSectionStyles = styled(Box)`
  background-image: url(${heroBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  height: 100vh;
  padding-top: 108px;
  margin-top: -108px;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    background-image: url(${heroMobileBackground});
    background-position-x: 19%;
    display: flex;
    justify-content: center;
    padding: 0;
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }

  .hero-leftContentBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({ theme }) => theme.breakpoints.up('md')} {
      align-items: flex-start;
      text-align: left;
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
      align-items: center;
      text-align: center;
      height: 100%;
      justify-content: space-between;
      padding-bottom: 20px;
    }
  }
  .hero-rightContentBox {
    height: 100%;
    width: 85%;
    margin-top: -3em;

    display: flex;
    flex-direction: column;
    margin-bottom: -2.8rem;
    justify-content: center;
    align-items: flex-start;

    ${({ theme }) => theme.breakpoints.down('md')} {
      align-items: center;
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 2em;
      display: none;
    }
  }
  .hero-title {
    color: ${({ theme }) => theme.palette.text.secondary};

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 2.7991rem;
      line-height: 70px;
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 2.1991rem;
      line-height: 48px;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      font-size: 1.5991rem;
      line-height: 36px;
    }
  }
  .hero-subTitle {
    font-family: Roboto Bold;
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-top: 1.5rem;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 1.125em;
    }

    ${({ theme }) => theme.breakpoints.only('xs')} {
      font-size: 1rem;
    }
  }
  .hero-interactionBox {
    display: flex;
    max-width: fit-content;
    margin-top: 2em;
    ${({ theme }) => theme.breakpoints.up('md')} {
      flex-direction: row;
      align-items: flex-start;
      gap: 0;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 3rem;
    }
  }
  .hero-emailInput {
    & .MuiFilledInput-input {
      background-color: #f9f9f9;
      border-radius: 30px;
      font-size: 1.125em;
      text-transform: none;
      font-family: Roboto Regular;
      padding-left: 1.4em;
      padding-right: 3em;
      height: 1.325em;
    }
    & .MuiFilledInput-root {
      background-color: rgb(0 0 0 / 0%);
    }
    & .MuiFilledInput-root:before {
      transform: scaleX(0);
    }
    & .MuiFilledInput-root.Mui-focused:after {
      transform: scaleX(0.9);
      border-bottom: 2px solid #3f826d;
    }
    & .MuiFilledInput-root:hover,
    .MuiFilledInput-root.Mui-focused {
      background-color: rgb(0 0 0 / 0%);
    }
    & .MuiInputLabel-filled {
      color: #acacac;
      transform: translate(12px, 19px) scale(1);
      margin-left: 0.8em;
    }
    & .MuiInputLabel-shrink {
      color: #3f826d;
      transform: translate(12px, 7px) scale(0.75);
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      width: 22em;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      width: 17em;
    }
  }

  .hero-picture {
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: contain;
    aspect-ratio: 555/530;

    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 80%;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      width: 100%;
    }
  }
` as StyledPropsFix;

export const ContainerBox = styled(Box)`
  display: flex;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 2rem 0 0 0;
    flex-direction: column;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 100%;
  }
` as StyledPropsFix;
export const LeftBox = styled(Box)`
  position: relative;
` as StyledPropsFix;
export const RightBox = styled(Box)`` as StyledPropsFix;
export const InteractionBox = styled(Box)`` as StyledPropsFix;
export const ActionButton = styled(Button)`
  text-transform: none;
  font-family: Roboto Regular;
  color: ${({ theme }) => theme.palette.text.secondary};
  background-color: rgb(64 130 109 / 100%);
  white-space: nowrap;
  &:hover {
    background-color: #58a88f;
  }
  font-size: 1.125em;
  margin-right: 0;
`;
export const EmailInput = styled(TextField)``;

export const Title = styled(Typography)`
  position: relative;
  font-family: Poppins SemiBold;
  color: white;
` as StyledPropsFix;

export const SubTitle = styled(Typography)`
  position: relative;
  font-family: Roboto Regular;
` as StyledPropsFix;

export const BackDrop = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(180deg, #7a7997 0%, #2a333c 100%);
  opacity: 0.7;
  filter: blur(110.787px);
  transform: matrix(0.99, -0.1, 0.11, 0.99, 0, 0);
` as StyledPropsFix;
