import React from 'react';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import imageNr1 from 'images/indexPage/investment-growth-potential.webp';
import * as Styles from './styles';
import { useLocalise } from 'hooks/use-localise';

const InvestmentGrowth = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  const { localise } = useLocalise('IndexView', 'InvestmentGrowth');

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.Title component="h1" variant="h4" color="text.primary">
          {localise('title')}
        </Styles.Title>
        <Styles.PictureBox>
          <Styles.Picture
            src={imageNr1}
            component={'img'}
            alt={localise('alt')}
            height={1}
            width={1}
          />
        </Styles.PictureBox>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default InvestmentGrowth;
