import React from 'react';
import Box from '@mui/material/Box';
import firstStepImage from 'images/stepsOfInvestor/1.png';
import forthStepImage from 'images/stepsOfInvestor/4.png';
import fifthStepImage from 'images/stepsOfInvestor/5.png';
import sixthStepImage from 'images/stepsOfInvestor/6.png';
import * as Styles from './Styles';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { useLocalise } from 'hooks/use-localise';

const picList = [
  {
    id: 1,
    name: '1',
    src: firstStepImage,
    alt: '1',
    description: '1',
  },
  {
    id: 2,
    name: '2',
    src: forthStepImage,
    alt: '2',
    description: '2',
  },
  {
    id: 3,
    name: '3',
    src: sixthStepImage,
    alt: '3',
    description: '3',
  },
  {
    id: 4,
    name: '4',
    src: fifthStepImage,
    alt: '4',
    description: '4',
  },
];

const StepsOfInvestor = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  const { localise } = useLocalise('IndexView', 'StepsOfInvestor');

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.Title component="h1" variant="h4">
          {localise('title')}
        </Styles.Title>

        <Styles.CardGrid container spacing={2} rowSpacing={2}>
          {picList.map((card, index, wholeList) => {
            const lastCard: boolean = wholeList.length - 1 === index;
            const sidePadding = '2.8em';
            const verticalPadding = '1.5em';

            return (
              <Styles.CardGridContainer
                item
                key={card.id}
                xs={12}
                sm={6}
                md={3}
              >
                <Styles.Card
                  elevation={0}
                  sx={{
                    padding: `${verticalPadding} ${sidePadding}`,
                    paddingLeft: '1.7em',
                    paddingRight: 0,
                  }}
                >
                  <Styles.PictureBox>
                    <Styles.Picture
                      src={card.src}
                      component={'img'}
                      alt={localise(card.alt + '_alt')}
                      height={1}
                      width={1}
                    />
                  </Styles.PictureBox>
                  <Box textAlign="center">
                    <Styles.CardNumeration>{card.name}</Styles.CardNumeration>
                    <Styles.CardDescription>
                      <p
                        style={{
                          display: 'inline',
                          marginLeft: lastCard ? '-1.2em' : 0,
                        }}
                      >
                        {localise(card.description + '_description')}
                      </p>
                    </Styles.CardDescription>
                  </Box>
                  <Styles.PointingTriangle />
                </Styles.Card>
              </Styles.CardGridContainer>
            );
          })}
        </Styles.CardGrid>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default StepsOfInvestor;
