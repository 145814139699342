import React from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import { Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import * as Styles from './styles';
import SeeMoreButton from './SeeMoreButton';
import { useLocalise } from 'hooks/use-localise';
import { Investor_Platform_URL } from 'links';
import useCookieFromClickID from 'hooks/useCookieFromClickID';

const Hero = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  const { localise } = useLocalise('IndexView', 'Hero');
  const { clickID } = useCookieFromClickID();

  const hardCodedLinks = {
    createAccount:
      Investor_Platform_URL +
      '/sign-up' +
      (clickID ? `?external_id=${clickID}` : ''),
  };

  return (
    <Styles.HeroSectionStyles>
      <Styles.ContainerBox>
        <Container
          maxWidth={{ md: sizeOfGrid + 'px' }}
          display={{ xs: 'flex', sm: 'flex', md: 'flex' }}
          justifyContent={{ xs: 'center', sm: 'center', md: 'unset' }}
          flexDirection={{
            md: 'row',
            sm: 'column-reverse',
            xs: 'column-reverse',
          }}
          alignItems={{ sm: 'center', xs: 'center' }}
          paddingBottom={{ md: 0, sm: 0, xs: 0 }}
          height={{ md: 'inherit', sm: '100%', xs: '100%' }}
          paddingTop={{ md: '32px', sm: '32px', xs: '49px' }}
        >
          <Styles.LeftBox className="hero-leftContentBox">
            <Box>
              <Styles.BackDrop />
              <Styles.Title component="h1" className="hero-title">
                {localise('title')}
              </Styles.Title>
            </Box>

            <Styles.InteractionBox
              className="hero-interactionBox"
              sx={{ scale: { md: '1', sm: '1', xs: '0.8' } }}
            ></Styles.InteractionBox>
          </Styles.LeftBox>
          <Styles.RightBox className="hero-rightContentBox"></Styles.RightBox>
        </Container>
      </Styles.ContainerBox>
      <Divider height="7em" />
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '6rem',
          }}
        >
          <Styles.ActionButton href={hardCodedLinks['createAccount']}>
            {localise('createAccount')}
          </Styles.ActionButton>
          <SeeMoreButton href="#key-statistics" />
        </Box>
      </Box>
    </Styles.HeroSectionStyles>
  );
};

export default Hero;
