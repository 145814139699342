import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { StyledPropsFix } from 'types';
import { Button, Grid, Paper } from '@mui/material';

export const Title = styled(Typography)`
  font-family: Poppins SemiBold;
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 2.1em;
    margin: 1em 2em;
    line-height: 50px;
    text-align: center;
    order: initial;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 1.6em;
    margin-bottom: 1em;
    line-height: 40px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    font-size: 1.2em;
    margin: 0 0 1em 0;
    line-height: inherit;
  }
` as StyledPropsFix;

export const ContentContainer = styled(Container)<{ sizeofgrid?: string }>`
  height: 100;
  position: relative;
  margin: 1em 2em;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    align-items: initial;
    ${({ sizeofgrid }) => sizeofgrid && `max-width: ${sizeofgrid}px;`}
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    align-items: center;
  }
`;

export const ContentBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.background.level1};
`;

export const PictureBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    position: relative;
    width: 8.4em;
    order: initial;
  }

  ${({ theme }) => theme.breakpoints.only('sm')} {
    display: flex;
    position: relative;
    width: 6.4em;
    order: 1;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    display: flex;
    position: relative;
    width: 6.4em;
    order: 1;
  }
`;

export const Picture = styled(Box)`
  object-fit: contain;
` as StyledPropsFix;

export const CardDescription = styled(Box)`
  font-family: Roboto Regular;
  font-size: 1.1em;
  text-align: center;
` as StyledPropsFix;

export const CardNumeration = styled(Box)`
  font-family: Roboto Bold;
  font-size: 3.125em;
` as StyledPropsFix;

export const ActionButton = styled(Button)`
  text-transform: none;
  font-family: Roboto Regular;
  color: ${({ theme }) => theme.palette.text.secondary};
  width: min-content;
  align-self: center;
  white-space: nowrap;
  margin-top: 5.18em;
  &:hover {
    background-color: #58a88f;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.125em;
    margin: 0;
    transform: scale(0.7);
  }
` as StyledPropsFix;

export const CardGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    // margin: 0 auto;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    width: 100%;
    // margin: 0 auto;
    margin-bottom: 3.6em;
  }

  ${({ theme }) => theme.breakpoints.only('xs')} {
    width: 100%;
    // margin: 0 auto;
    margin-bottom: 3.6em;
    justify-content: center;
  }
` as StyledPropsFix;

export const PointingTriangle = styled(Box)`
  width: 0;
  height: 0;
  border-left: 80px solid white;
  position: absolute;
  right: -5em;
  top: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    border-bottom: 160px solid transparent;
    border-top: 160px solid transparent;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    border-bottom: 145px solid transparent;
    border-top: 145px solid transparent;
  }

  ${({ theme }) => theme.breakpoints.only('xs')} {
    border-bottom: 145px solid transparent;
    border-top: 145px solid transparent;
  }
` as StyledPropsFix;

export const CardGridContainer = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 0 !important;
    margin: -0.7em 0;
    transform: scale(0.65);
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    padding-left: 0 !important;
    margin: -1.2em 0;
    transform: scale(0.87);
  }

  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding-left: 0 !important;
    flex-basis: 52%;
    margin: -1.8em 0;
    transform: scale(0.8);
  }
` as StyledPropsFix;

export const Card = styled(Paper)`
  align-items: center;
  position: relative;
  background: white;
  display: flex;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: column;
    height: 20em;
    width: 16em;
    justify-content: initial;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    flex-direction: column-reverse;
    height: 18em;
    width: 14em;
    justify-content: space-between;
  }

  ${({ theme }) => theme.breakpoints.only('xs')} {
    flex-direction: column-reverse;
    height: 18em;
    width: 14em;
    justify-content: space-between;
  }
` as StyledPropsFix;
